import React, { useCallback, useEffect, useState } from "react";
import { Input, Typography, Button, Spin } from "antd";
import { useDropzone } from "react-dropzone";
import { useAuthContext } from "../../context/auth-context.tsx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Light from "../../assets/images/light.png";
import Dark from "../../assets/images/dark.png";
import * as Yup from "yup";
import axiosClient from "../../utils/axios.tsx";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";

const Profile = () => {
  const { user, updateUser } = useAuthContext();
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result as string);
        setFormEdited(true);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setFormEdited(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
  });

  useEffect(() => {
    setImage(user?.profile_image || null);
  }, [user]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: user?.name || "",
        profile_image: image,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", values.first_name);
        if (image !== null) {
          formData.append("profile_image", image);
        }
        console.log("Form Data: ", formData);
        axiosClient()
          .patch(`/users/${user?.user_id}`, formData)
          .then((res) => {
            toast.success("Profile updated successfully");
            setIsLoading(false);
            setFormEdited(false);
            setImage(res?.data?.profile_image || null);
            updateUser({
              ...user,
              name: res?.data?.name,
              profile_image: res?.data?.profile_image || null,
            });
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            setIsLoading(false);
          });
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div>
            <div className="flex items-center gap-6">
              <Grid container spacing={2}>
                <Grid item xs={5.9}>
                  <div className="">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      First name
                    </Text>
                    <Field
                      type="text"
                      name="first_name"
                      as={Input}
                      placeholder=""
                      onChange={(event) => {
                        setFieldValue("first_name", event.target.value);
                        setFormEdited(true);
                      }}
                      className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
                    />
                    <div className="h-[10px]">
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={5.9}>
                  <div className="">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Last name
                    </Text>
                    <Field
                      type="text"
                      name="last_name"
                      as={Input}
                      placeholder=""
                      onChange={(event) => {
                        setFieldValue("last_name", event.target.value);
                        setFormEdited(true);
                      }}
                      className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
                    />
                    <div className="h-[10px]">
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="w-full mt-4" style={{ position: "relative" }}>
              <img src={Light} alt="" className="lightimg" />
              <img src={Dark} alt="" className="darkimg" />
              <Text className="text-[#344054] text-[14px] font-medium">
                Email address
              </Text>
              <Input
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                  paddingLeft: "40px",
                }}
                placeholder=""
                value={user?.email}
                disabled
                className="w-full h-[44px] placeholder:text-[#667085] text-[#26292e] cursor-not-allowed rounded-[8px] mt-2 bg-[#F9FAFB]"
              />
            </div>
            <div className="flex items-start gap-5 mt-6 profiled">
              <div className="h-[125px] w-[150px] rounded-[50%] bg-gray-200 overflow-hidden">
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded"
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <img
                    src="/images/profile.svg"
                    className="object-cover w-full h-full"
                    alt=""
                  />
                )}
              </div>
              <div className="border border-[#EAECF0] cursor-pointer w-full rounded-[12px] flex justify-center items-center p-5 coverImage">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <div className="text-center flex items-center justify-center">
                      <div>
                        <div className="flex justify-center w-full">
                          <img
                            src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(4).svg?updatedAt=1709799572323"
                            alt=""
                            className="h-[45px] w-[45px]"
                          />
                        </div>
                        <p className="text-[#475467] text-[12px]">
                          <span className="text-[#004EEB] text-[14px] font-semibold">
                            Click to upload
                          </span>{" "}
                          or drag and drop <br />
                          SVG, PNG, JPG or GIF (max. 800x400px)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-t w-full mt-6 topBorder">
              <div className="pt-4 flex gap-3 items-center justify-end">
                <Button className=" h-[44px] cancel bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]">
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  disabled={isLoading}
                  className="h-[44px] min-w-[120px] changes w-auto bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                >
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <>{formEdited ? "Save Changes" : "Edit Details"}</>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Profile;
